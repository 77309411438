<template>
  <draggable
      v-bind="dragOptions"
      tag="div"
      class="item-container"
      :list="list"
      :value="value"
      @input="emitter"
  >
    <div class="item-group"
         :key="el.type === 'documents' ? 'document-' + el.id : (el.type === 'files' ? 'file-' + el.id : 'folder-' + el.id)"
         v-for="(el, index) in realValue">
      <div class="item d-flex" :class="{'is-active': isActive && checkContains(el.id, el.type)}"
           v-if="!el.parent_id || checkContains(el.parent_id, parentType)" @mouseover="hoverRootInMobile()" @mouseleave="mouseLeaveRootInMobile()">
        <hr class="line-connect-child-item"/>
        <img src="@/assets/svgs/ic_loading.svg" class="icon-loading m__left--20"
             v-if="specialLoading && el.id === loadChildId">
        <img v-else-if="el.type !== 'documents' && el.type !== 'files'"
             class="icon-folder-tree m__right--10 m__left--20"
             @click="goToDocument(el)"
             src="@/assets/svgs/ic_File_1.svg">
        <a v-else-if="el.type === 'documents'" @click="goToDocument(el)">
          <i class="fas fa-file m__right--10 m__left--21 icon-file" style="color: #016FC1"></i>
        </a>
        <a v-else @click="goToDocument(el)">
          <img src="@/assets/svgs/Files/ic_doc2.svg" class="icon-file m__right--10 m__left--20"
               v-if="el.file_format * 1 === 1">
          <img src="@/assets/svgs/Files/ic_PDF2.svg" class="icon-file m__right--10 m__left--20"
               v-if="el.file_format * 1 === 2">
          <img src="@/assets/svgs/Files/ic_image2.svg" class="icon-file m__right--10 m__left--20"
               v-if="el.file_format * 1 === 3">
        </a>
        <span class="folder-box folder-name d-flex align-items-center content-folder-action">
          <span class="file-name-editable has-text-nowrap" @click="goToDocument(el)">
            <input type="text"
                   :class="{'border-error': !el.name || el.name.length > 50}"
                   class="input input-edit-directly"
                   :placeholder="$t('folders.messages.validation.folder_name_required')"
                   v-if="el.id === editId && el.type === 'folders'"
                   v-model="el.name"
                   v-click-out="() => clickOutChangeName(el)"
                   @keyup.enter="updateFolder({id: el.id, name: el.name, type: el.type})">
            <template v-if="el.id !== editId">
            <span class="folder-name color__gray_tree fw__bold" :data-tooltip="el.type === 'files' && el.original_name">
              {{ el.type !== 'files' ? el.name : getDisplayFileName(el.original_name) }}
            </span>
            </template>
          </span>
          <a class="icon-caret-down"
             @click="toggleChild(el, el.type)"
             v-if="el.children * 1 || (el.children && el.children.length) || el.documents">
            <i class="fas fa-caret-down m__left--20 m__right--10 action-icon"></i>
          </a>
        </span>
        <template v-if="!iconRoot">
          <template v-if="el.type !== 'files'">
            <a @click="editFileName(el.id, el.type, el.name, true)"
               class="action-buttons has-tooltip-top"
               :id="'update-folder-name-' + el.id"
               :data-tooltip="$t('tooltip.folders.edit')"
               v-if="el.type === 'folders' && (userInfo && (userInfo.role_id !== 4 || (userInfo.office_id === el.office || userInfo.office_id === el.office_id)))">
              <img class="icon-action"
                   src="@/assets/svgs/Files/ic_edit.svg" width="20px" height="25px">
            </a>
            <a @click="createFolder({id: el.id, name: el.name, type: 'folder', list: realValue, parent: el.parent_id})"
               class="action-buttons has-tooltip-top"
               :data-tooltip="tooltip && $t('tooltip.folders.create_folder')"
               v-if="el.type !== 'documents' && (userInfo && (userInfo.role_id !== 4 || (userInfo.office_id === el.office || userInfo.office_id === el.office_id)))">
              <img class="icon-action"
                   src="@/assets/svgs/Files/ic_add file_1.svg" width="20px" height="25px">
            </a>
            <a @click="createFolder({id: el.id, name: el.name, type: 'document', parent: el.parent_id})"
               class="action-buttons has-tooltip-top"
               :data-tooltip="$t('tooltip.folders.create_document')"
               v-if="el.type !== 'documents' && (userInfo && (userInfo.role_id !== 4 || (userInfo.office_id === el.office || userInfo.office_id === el.office_id)))">
              <img class="icon-action"
                   src="@/assets/svgs/Files/ic_add file_2.svg" width="20px" height="25px">
            </a>
            <a @click="deleteFolder({id: el.id, type: el.type, parent: el.parent_id})"
               v-if="el.type !== 'documents' && (userInfo && (userInfo.role_id !== 4 || userInfo.id === el.owner_id)) && !el.is_system"
               class="action-buttons has-tooltip-top"
               :data-tooltip="$t('tooltip.folders.delete_folder')">
              <img class="icon-action"
                   src="@/assets/svgs/Files/ic_delete.svg" width="20px" height="25px">
            </a>
          </template>
        </template>

      </div>
      <nested-draggable class="item-sub"
                        :list="el.children && isArray(el.children) ? el.children : []"
                        :parent-type="el.type"
                        :parent-id="el.id"
                        :load-more="checkLoadMore(el)"
                        :active-id="activeIds"
                        :is-loading-more="isLoadingMore"
                        @toggle="toggleItem()"
                        @load-children="loadChildren($event)"
                        @load-more="actionLoadMore($event)"
                        @create="createFolder($event)"
                        @update="updateFolder($event)"
                        @delete="deleteFolder($event)"/>
      <div class="item d-flex" :class="{'is-active': isActive && checkContains(el.id, el.type)}"
           v-if="index === realValue.length - 1 && loadMore && checkContains(el.parent_id, parentType)">
        <img src="@/assets/svgs/ic_loading.svg" class="icon-loading m__left--20" v-if="isLoadingMore">
        <a @click="actionLoadMore(el)" class="load-more" v-else>{{ $t('folders.load_more') }}</a>
      </div>
    </div>
  </draggable>
</template>

<script>
import draggable from "vuedraggable"
import findIndex from 'lodash/findIndex'
import truncate from "lodash/truncate";
import isArray from 'lodash/isArray'
import isNumber from 'lodash/isNumber'
import {mapGetters} from 'vuex'
import {checkDevice} from "@/helpers/utils";

export default {
  name: "nested-draggable",
  components: {
    draggable
  },
  props: {
    value: {
      required: false,
      type: [Array, Number],
      default: null
    },
    list: {
      required: false,
      type: [Array, Number],
      default: null
    },
    activeId: {
      required: false,
      type: Array,
      default: () => []
    },
    parentType: {
      type: String,
      default: 'folders'
    },
    parentId: {
      type: [Number, String],
      default: null
    },
    errorMessage: {
      type: String,
      default: ''
    },
    tooltip: {
      type: Boolean,
      default: true
    },
    loadMore: {
      type: Boolean,
      default: false
    },
    isLoadingMore: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconRoot: false,
      isActive: false,
      activeIds: [],
      editId: null,
      delay: 500,
      clicks: 0,
      timer: null,
      editClicked: false,
      oldName: '',
      isArray: isArray,
      listClone: [],
      loadChildId: null,
    }
  },
  computed: {
    ...mapGetters(['specialLoading', 'dataLoaded']),
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: true,
        ghostClass: "ghost"
      };
    },
    // this.value when input = v-model
    // this.list  when input != v-model
    realValue() {
      return this.value ? this.value : (this.list && isArray(this.list) ? this.list : [])
    }
  },
  watch: {
    activeId(val) {
      this.activeIds = [...val]
    },
    errorMessage(val) {
      if (val) {
        this.$toast.error(val)
      }
    }
  },
  methods: {
    hoverRootInMobile() {
      if (checkDevice(navigator)) {
        this.iconRoot = false
      } else {
        this.iconRoot = false
      }
    },
    mouseLeaveRootInMobile() {
      if (checkDevice(navigator)) {
        this.iconRoot = true
      } else {
        this.iconRoot = false
      }
    },
    emitter(value) {
      this.$emit("input", value)
    },

    checkLoadMore(el) {
      let index = findIndex(this.dataLoaded, e => e.id === el.id)
      if (index >= 0 && el.children) {
        return this.dataLoaded[index].data && this.dataLoaded[index].data.children.length > el.children.length
      }

      return false
    },

    actionLoadMore(el) {
      this.$emit('load-more', el)
    },

    toggleEdit(event) {
      let parent = event.target.closest('.item-group')
      if (parent.classList.contains('is-active')) {
        parent.classList.remove('is-active')
      } else {
        parent.classList.add('is-active')
      }
    },

    createFolder({id, name, type, list, parent}) {
      this.$emit('create', {id, name, type, list, parent})
    },

    clickOutChangeName(e) {
      if (!this.editClicked) {
        this.$nextTick(() => {
          this.editId = null
          e.name = this.oldName
        })
      }
      this.editClicked = false
    },

    getDisplayFileName(fileName) {
      let displayName = ''
      let ext = fileName.split('.').pop()
      let name = ''
      fileName.split('.').map((e, i) => {
        if (i < fileName.split('.').length - 1) {
          name += e
        }
      })
      if (fileName && fileName.length > 20) {
        displayName = truncate(name, {
          'length': 20,
          'omission': '...'
        }) + '.' + ext
      } else {
        displayName = fileName
      }

      return displayName
    },

    getDisplayName(name) {
      if (name && name.length > 20) {
        return truncate(name, {
          'length': 20,
          'omission': '...'
        })
      }

      return name
    },

    updateFolder(event) {
      if (!event.name || event.name.length > 50) {
        let index = findIndex(this.realValue, e => e.id === event.id)
        if (index >= 0) {
          this.realValue[index].name = this.oldName
        }
        if (!event.name) {
          this.$toast.error(this.$t('folders.messages.validation.folder_name_required'))
        }
        if (event.name.length > 50) {
          this.$toast.error(this.$t('folders.messages.validation.folder_name_maxLength'))
        }
      } else {
        if (event.name !== this.oldName) {
          this.$emit('update', event)
        }
      }
      this.editId = null
      this.oldName = ''
    },

    deleteFolder({id, type, parent}) {
      this.$emit('delete', {id, type, parent})
    },

    toggleItem() {
      this.$emit('toggle')
    },

    loadChildren(el) {
      this.loadChildId = el.id
      this.$emit('load-children', el)
    },

    toggleChild(el, type) {
      if (el.id) {
        this.isActive = !this.isActive
        if (findIndex(this.activeIds, (e) => e.id === el.id) >= 0) {
          this.activeIds.splice(findIndex(this.activeIds, (e) => e.id === el.id), 1)
        } else {
          if ((el.children && isNumber(el.children)) || (el.documents && isNumber(el.documents))) {
            this.loadChildren(el)
          }
          this.activeIds = [...this.activeIds, ...[{id: el.id, type}]]
        }
        this.toggleItem()
      }
    },

    checkContains(id, type) {
      return findIndex(this.activeIds, (e) => e.id === id && e.type === type) >= 0
    },

    editFileName(id, type, name, button = false) {
      this.editClicked = button
      this.oldName = name
      if (type === 'folders') {
        this.editId = id
        this.$nextTick(() => {
          this.$el.querySelector('.input-edit-directly').focus()
        })
      }
    },

    goToDocument(el) {
      if (el.type === 'documents') {
        this.$router.push({
          name: el.document_type.type === 1 ? 'BtoB' : 'BtoC',
          params: {id: el.id},
          query: {sid: el.service_user_id}
        }, () => {
        })
      } else {
        if (el.children || el.documents) {
          this.toggleChild(el, el.type)
        }
      }
    },
  },
  created() {
    if (checkDevice(navigator)) {
      this.iconRoot = true
    } else {
      this.iconRoot = false
    }
  },
};
</script>
<style lang="scss" scoped>
.item-container {
  //max-width: 30rem;
  margin: 0;

  &.item-sub {
    margin: 0 0 0 3rem;
    border-left: 1px solid lightgrey;
    //.item-group {
    //  &:not(:last-child) {
    //    border-left: 1px solid #cccccc;
    //  }
    //  &:last-child {
    //    .item {
    //      border-left: 1px solid #cccccc;
    //    }
    //  }
    //}
  }
}

.item-group {
  &.is-active > .item {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    margin-bottom: 0 !important;
  }

  .card {
    display: none;
    margin-left: 5px;
    margin-right: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &.is-active > .card {
    display: block !important;
  }
}

.item {
  padding: 0.75rem 1rem;
  background-color: #fff;
  position: relative;
  width: fit-content;

  &:hover {
    cursor: pointer;

    .action-buttons {
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }
  }

  .action-buttons {
    opacity: 0;
    color: #808080;

    .fa-caret-down {
      font-size: 20px;
    }
  }

  .action-buttons {
    &:hover {
      cursor: pointer;

      .icon-action  {
        transform: scale(1.2);
        transition: all 0.3s;

        &.icon-update {
          color: $success;
        }

        &.icon-create {
          color: $success;
        }

        &.icon-delete {
          color: $danger;
        }
      }
    }
  }

  .line-connect-child-item {
    width: calc(1rem + 20px);
    margin: 0;
    background-color: lightgrey;
    position: absolute;
    left: 0;
    top: 50%;
    height: 1px;
  }
}

.item-sub {
  margin: 0 0 0 1rem;
}

.field {
  .field-label {
    text-align: left !important;
  }
}

.icon-folder-tree {
  width: 25px !important;
  height: 25px !important;
}

.content-folder-action:hover {
  .folder-name {
    color: #016FC1 !important;
  }
  .action-icon {
    color: #016FC1 !important;
  }
  background-color: #E1F2FC !important;
}

.folder-name {
  font-size: 14px;
}

//.Icon-file {
//  width: 1em;
//  height: 1.2em;
//}

.file-name-editable {
  height: 20px;
}

.input-edit-directly {
  border: none;
  border-bottom: 1px solid $blue_main;
  box-shadow: none;
  width: auto;
  height: 21px;
  padding-left: 0;
  font-size: 1em;
  border-radius: unset !important;
}

.item {
  .icon-caret-down {
    svg {
      color: #808080;
      transform: rotateZ(0deg);
      transition: all 0.3s ease-in-out;
    }
  }

  &.is-active {
    .icon-caret-down {
      svg {
        transform: rotateZ(180deg);
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.load-more {
  font-size: 14px;
}

.folder-box {
  background-color: $gray_dark;
  padding: 2px 15px;
  border-radius: 12px;
  height: 25px !important;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) {
  /*.folder-name {*/
  /*  overflow: hidden;*/
  /*  white-space: nowrap;*/
  /*  max-width: 150px !important;*/
  /*  text-overflow: ellipsis;*/
  /*  width: 100px !important*/
  /*}*/
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 0) {
  .action-buttons {
    margin-left: 10px !important;
    width: 30px !important;
    height: 25px !important;
  }
  .icon-action {
    width: 20px !important;
    height: 25px !important;
  }
}

.action-buttons {
  margin-left: 10px !important;
  width: 25px !important;
  height: 25px !important;
}

.icon-action {
  width: 20px !important;
  height: 25px !important;
}
</style>
