<template>
  <div>
    <div class="folder-title background__blue_title">
      <p class="title-secondary fs-20 color__blue_main p--0 fw__bold">{{ $t('folders.input_field.file_list') }}</p>
    </div>
    <div class="page-main-content p__bottom--0 p__top--40" v-drag-scroll.y="true">
      <div class="columns overflow-hidden box-tree-folders m-0"
           @mousedown="dragTree('mouse-drag')"
           @mouseup="dragTree('mouse-drop')"
           v-drag-scroll="true">
        <div class="column sidebar-document is-full p__bottom--50 p--0">
          <div class="item-group">
            <div class="item" @mouseover="hoverRootInMobile()" @mouseleave="mouseLeaveRootInMobile()">
              <img class="icon-folder-tree m__right--10 m__left--0"
                   src="@/assets/svgs/ic_File_1.svg">
              <span class="folder-box align-items-center content-folder-action">
                <span class="file-name-editable folder-name color__gray_tree fw__bold">Root</span>
              </span>
              <template v-if="!iconRoot">
                <a @click="createFolder({id: null, name: 'Root', type: 'folder'})"
                   v-if="(userInfo && (userInfo.role_id !== 4))"
                   :data-tooltip="displayTooltip && $t('tooltip.folders.create_folder')"
                   class="action-buttons has-tooltip-bottom">
                  <img class="m__left--10 icon-action"
                       src="@/assets/svgs/Files/ic_add file_1.svg">
                </a>
                <a @click="createFolder({id: null, name: 'Root', type: 'document'})"
                   v-if="(userInfo && (userInfo.role_id !== 4))"
                   :data-tooltip="displayTooltip && $t('tooltip.folders.create_document')"
                   class="action-buttons has-tooltip-bottom">
                  <img class="m__left--10 icon-action"
                       src="@/assets/svgs/Files/ic_add file_2.svg">
                </a>
              </template>
            </div>
            <nested-draggable v-model="listFolders"
                              class="item-sub"
                              :tooltip="displayTooltip"
                              parent-type="folders"
                              :error-message="errorMessage"
                              :is-loading-more="isLoadingMore"
                              @toggle="toggleItem()"
                              @load-children="loadChildren($event)"
                              @load-more="loadMore($event)"
                              @create="createFolder($event)"
                              @update="updateFolder($event)"
                              @delete="deleteFolder($event)">
            </nested-draggable>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" id="modal-create-folder">
      <div class="modal-background"></div>
      <div class="modal-card" v-click-out="() =>{closeModal('modal-create-folder'); cancelCreateFolder}">
        <div class="modal-card-head background__gray_dark">
          <p class="modal-card-title input-title color__blue_main text-center fw__bold" style="font-size: 20px">{{ $t('folders.page.create') }}</p>
          <button class="delete" aria-label="close" @click="closeModal('modal-create-folder')"></button>
        </div>
        <section class="modal-card-body">
          <div class="columns is-justify-content-center p__top--30 w-tablet-90">
            <div class="column is-narrow-tablet is-three-quarters control">
              <label class="label input-title color__blue_button label-tablet" style="font-size: 14px; font-weight: bold !important;">{{ $t('folders.modal.input_field.name.label') }}
                <i class="required-note" :data-tooltip="$t('tooltip.required')">*</i>
              </label>
              <input type="text" class="input"
                     :placeholder="$t('folders.modal.input_field.name.placeholder')"
                     :class="{'is-error': ((!vuelidate.folder.name.required || !vuelidate.folder.name.maxLength) && vuelidate.$dirty) || createErrorMessage}"
                     v-model="folder.name"
                     @keyup="createErrorMessage = ''"
                     @keyup.enter="submitChange()">
              <span class="error pt-1" v-if="createErrorMessage">
                {{ createErrorMessage }}
              </span>
              <template v-else-if="vuelidate.$dirty">
                <span class="error pt-1" v-if="!vuelidate.folder.name.required">
                  {{ $t('folders.messages.validation.folder_name_required') }}
                </span>
                <span class="error pt-1" v-if="!vuelidate.folder.name.maxLength">
                  {{ $t('folders.messages.validation.folder_name_maxLength') }}
                </span>
              </template>
            </div>
          </div>
        </section>
        <div class="modal-card-foot is-justify-content-center background__white p__top--50 p__bottom--50">
          <button class="button is-success close-modal btn-save background__blue_main"
                  @click="submitChange()">{{ $t('folders.buttons.save') }}
          </button>
          <button class="button btn-back background__grey close-modal"
                  @click="closeModal('modal-create-folder'); cancelCreateFolder()">{{
              $t('users_management.buttons.cancel')
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ACTION_SET_ACTIVE_SIDEBAR, ACTION_SET_DATA_LOADED, ACTION_SET_SPECIAL_LOADING} from "@/stores/common/actions";
import NestedDraggable from './components/nested-draggable'
import FolderService from "@/services/FolderService";
import {findDeep, groupDeep, hasDeep} from "@/helpers/recursive";
import {required, maxLength} from 'vuelidate/lib/validators'
import {dragscroll} from 'vue-dragscroll'
import findIndex from "lodash/findIndex";
import {mapGetters} from "vuex";
import {checkDevice} from "@/helpers/utils";

export default {
  name: "FoldersList",
  directives: {
    'drag-scroll': dragscroll
  },
  components: {NestedDraggable},
  data() {
    return {
      iconRoot: false,
      listFolders: [],
      folder: {
        name: '',
        parent_id: null,
      },
      errorMessage: '',
      createErrorMessage: '',
      displayTooltip: true,
      modalOpened: false,
      isLoadingMore: false,
    }
  },
  validations: {
    folder: {
      name: {
        required,
        maxLength: maxLength(50)
      }
    }
  },
  computed: {
    ...mapGetters(['dataLoaded']),
  },
  // watch: {
  //   listFolders(val) {
  //     console.log(val)
  //   },
  // },
  methods: {
    hoverRootInMobile() {
      if (checkDevice(navigator)) {
        this.iconRoot = false
      } else {
        this.iconRoot = false
      }
    },
    mouseLeaveRootInMobile() {
      if (checkDevice(navigator)) {
        this.iconRoot = true
      } else {
        this.iconRoot = false
      }
    },
    closeModal(id) {
      if (this.modalOpened) {
        this.$nextTick(() => {
          if (this.$el.querySelector(`#${id}`)) {
            this.$el.querySelector(`#${id}`).classList.remove('is-active')
          }
        })
        setTimeout(() => {
          this.modalOpened = false
        })
      }
    },
    toggleModal(id) {
      this.$nextTick(() => {
        if (this.$el.querySelector(`#${id}`)) {
          this.$el.querySelector(`#${id}`).classList.toggle('is-active')
        }
      })
    },
    toggleItem() {
      let boxTree = this.$el.querySelector('.box-tree-folders')
      if (boxTree) {
        setTimeout(() => {
          boxTree.scrollLeft += document.body.clientWidth
        })
      }
    },
    updateFolder(e) {
      FolderService.update(e.id, {name: e.name})
          .then(() => {
            this.$toast.success(this.$t('folders.messages.update_success'))
          })
          .catch((err) => {
            if (err && err.data && err.data.errors && err.data.errors.name) {
              this.errorMessage = err.data.errors.name[0]
            }
            this.getFolders()
          })
    },
    deleteFolder(e) {
      let folder = findDeep(this.listFolders, e.id, 'id', 'children', e.type)
      let deletable = hasDeep(folder.children, 'type', 'documents', 'children')
      if (deletable) {
        this.$popup(
            this.$t('folders.popup.delete_folder.title'),
            this.$t('folders.popup.delete_folder.content'),
            {
              okText: this.$t('folders.popup.delete_folder.ok'),
              cancelText: this.$t('folders.popup.delete_folder.cancel'),
            },
            {
              onOk: (d) => {
                FolderService.delete(e.id)
                    .then(() => {
                      this.$toast.success(this.$t('folders.messages.delete_success'))
                      this.loadChildren({id: e.parent, type: 'folders'})
                    })
                    .catch((err) => {
                      console.log(err);
                    })
                d.closeDialog()
              },
              onCancel: (d) => {
                d.closeDialog()
              }
            }
        )
      } else {
        this.$toast.warning(this.$t('folders.messages.can_not_delete_folder'))
      }
    },
    loadMore(el) {
      this.isLoadingMore = true
      setTimeout(() => {
        let index = findIndex(this.dataLoaded, e => e.id === el.parent_id)
        if (index >= 0) {
          let folder = findDeep(this.listFolders, el.parent_id, 'id', 'children', 'folders')
          if (this.dataLoaded[index].data.children.length > folder.children.length) {
            folder.children = [...folder.children, ...this.dataLoaded[index].data.children.slice(folder.children.length, folder.children.length + 10)]
          } else {
            this.dataLoaded.splice(index, 1)
          }
        }
        this.isLoadingMore = false
      }, 300)
    },
    loadChildren(el) {
      if (el.id) {
        this.$store.dispatch(ACTION_SET_SPECIAL_LOADING, 1)
        FolderService.detail(el.id)
            .then((res) => {
              this.$store.dispatch(ACTION_SET_SPECIAL_LOADING, 0)
              let folder = findDeep(this.listFolders, el.id, 'id', 'children', el.type)
              if (folder) {
                let cloneFolder = {...folder}
                let data = [...res.data.children,...res.data.documents]
                cloneFolder.children = [...groupDeep(data, 'documents', 'children', 'folders')]
                delete cloneFolder.documents
                this.$store.dispatch(ACTION_SET_DATA_LOADED, {id: el.id, data: cloneFolder})
                folder.children = cloneFolder.children.slice(0, 10)
                delete folder.documents
                // let listClone = [...this.listFolders]
                // listClone = [...setDeep(this.listFolders, el.id, 'id', 'children', el.type, folder)]
                // this.listFolders = [...listClone]
              }
            })
            .catch((err) => {
              this.$store.dispatch(ACTION_SET_SPECIAL_LOADING, 0)
              console.log(err)
            })
      } else {
        this.getFolders()
      }
    },
    async getFolders() {
      await FolderService.tree()
          .then((res) => {
            if (res && res.data) {
              let data = [...res.data.folders, ...res.data.free_documents.documents]
              this.listFolders = [...groupDeep(data, 'documents', 'children', 'folders')]
            }
          })
          .catch((err) => {
            console.log(err);
            this.$toast.error(err.data.message)
          })
    },
    updateFolders() {
      let folders = []

      function gd(a, k, d, i) {
        a.map((e) => {
          e.parent_id = i
          folders.push(e)
          if (e[d] && e[d].length > 0) {
            gd(e[d], k, d, e.id)
          }
        })
      }

      gd(this.listFolders, 'id', 'children', null)
      // folders.map((e) => {
      //   delete e.children
      // })
      console.log(folders)
    },
    createFolder(event) {
      this.vuelidate.$reset()
      this.folder.name = ''
      this.folder.type = event.type
      if (event.type === 'folder') {
        this.folder.parent_id = event.id
        this.folder.grand_parent_id = event.parent
        this.toggleModal('modal-create-folder')
        setTimeout(() => {
          this.modalOpened = true
          this.displayTooltip = false
        })
      } else {
        this.$router.push({name: 'CreateDocument', query: {parent_id: event.id, parent_name: event.name}}, () => {
        })
      }
    },
    cancelCreateFolder() {
      this.folder.name = ''
      this.displayTooltip = true
    },
    submitChange() {
      this.createErrorMessage = ''
      this.vuelidate.$touch()
      if (!this.vuelidate.$invalid) {
        this.createErrorMessage = ''
        FolderService.create(this.folder)
            .then(() => {
              this.$toast.success(this.$t('folders.messages.create_success'))
              this.loadChildren({id: this.folder.parent_id, type: 'folders'})
              this.closeModal('modal-create-folder')
              this.displayTooltip = true
            })
            .catch((err) => {
              if (err && err.data && err.data.errors && err.data.errors.name) {
                this.createErrorMessage = err.data.errors.name[0]
              }
            })
      }
    },
    dragTree(mouse) {
      if (this.$el.querySelector('.box-tree-folders')) {
        if(mouse !== 'mouse-drag') {
          this.$el.querySelector('.box-tree-folders').classList.remove('mouse-drag')
        } else {
          this.$el.querySelector('.box-tree-folders').classList.add(mouse)
        }
      }
    },
  },
  created() {
    this.$store.dispatch(ACTION_SET_ACTIVE_SIDEBAR, 'folders')
    localStorage.setItem('prev', 'folders')
    this.getFolders()
    if (checkDevice(navigator)) {
      this.iconRoot = true
    } else {
      this.iconRoot = false
    }
  }
}
</script>

<style lang="scss" scoped>
.folder-title {
  padding: 10px 60px;
  border-radius: 10px 10px 0 0;
}

.page-main-content {
  min-height: calc(100vh - 200px);
  overflow-x: auto;
  padding: 60px;
  border-radius: 0 0 10px 10px;
}

.box-tree-folders {
  cursor: grab;
}

.icon-folder {
  width: 20px;
}

button {
  &.button-folder {
    border: none !important;
  }
  &.btn-save {
    border-radius: 18px !important;
    height: 35px !important;
    width: 140px !important;
    border:  none !important;
    font-size: 16px !important;
  }
  &.btn-back {
    border-radius: 18px !important;
    height: 35px !important;
    width: 140px !important;
    border:  none !important;
    font-size: 16px !important;
    background-color: #E4E4E4 !important;
    color: #979797 !important;
  }
}

.modal {
  .modal-card {
    border-radius: 25px;

    .modal-card-body {
      min-height: 120px;

      .modal-field-content {
        padding: 0 20px;

        .toggle-password {
          position: absolute;
          top: 10px;
          right: 12px;
          color: gray;
          z-index: 10;

          &:hover {
            cursor: pointer;
          }
        }

        .dropdown-content {
          max-height: 200px;
          overflow-y: scroll;
        }
      }

      .modal-buttons-footer {
        margin-top: 70px;
      }
    }
  }
}

.item {
  /*padding: 1.5rem 1rem 0 1rem;*/
  background-color: #fff;
  position: relative;
  width: fit-content;
  display: flex;
  &:hover {
    cursor: pointer;

    .icon-action {
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }
  }

  .icon-action {
    opacity: 0;
    color: #808080;

    &.fa-caret-down {
      font-size: 20px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .line-connect-child-item {
    width: calc(1rem + 20px);
    margin: 0;
    background-color: lightgrey;
    position: absolute;
    left: 0;
    top: 50%;
    height: 1px;
  }
}

.icon-folder-tree {
  width: 25px;
  height: 25px;
}

.action-buttons {
  &:hover {
    cursor: pointer;

    .icon-action {
      transform: scale(1.2);
      transition: all 0.3s;
      fill: red !important;

      &.icon-update {
        color: $success;
      }

      &.icon-create {
        color: $success;
      }

      &.icon-delete {
        color: $danger;
      }
    }
  }
}

.folder-box {
  background-color: $gray_dark;
  padding: 0px 15px;
  border-radius: 12px;
  height: 25px;
}

@media only screen and (max-width: 495px) {
  .modal {
    .modal-card {
      border-radius: 25px;

      .modal-card-body {
        min-height: 150px;
        padding-top: 10px;

        .modal-field-content {
          padding: 0;
        }

        .modal-buttons-footer {
          margin-top: 70px;
        }
      }
    }
  }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .modal {
    .modal-card {
      .modal-card-body {
        min-height: 165px !important;
      }
    }
  }
  .w-tablet-90 {
    max-width: 90%;
    margin-left: 5%;
  }
  .folder-title {
    padding: 10px 20px !important;
  }
  .page-main-content {
    padding-left: 20px !important;
    padding-right: 20px !important;
    max-height: 70vh;
    overflow: hidden;
  }
}

.mouse-drag {
  cursor: grabbing !important;
}

.mouse-drop {
  cursor: grab;
}
.content-folder-action:hover {
  .folder-name {
    color: #016FC1 !important;
  }
  background-color: #E1F2FC !important;
}
.folder-name {
  font-size: 14px;
}
.input::placeholder {
  font-size: 12px;
}
.icon-action {
  width: 20px !important;
  height: 25px !important;
}
.item-sub {
  margin-left: 4rem !important;
}
</style>
